import React from "react";

const Filter = () => {
	return (
		<svg
			width="40"
			height="40"
			viewBox="0 0 40 40"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<rect
				x="6.66663"
				y="8.33337"
				width="26.6667"
				height="8.33333"
				rx="1"
				stroke="#3379FF"
				stroke-width="2"
				stroke-linejoin="round"
			/>
			<rect
				x="6.66663"
				y="23.3334"
				width="26.6667"
				height="8.33333"
				rx="1"
				stroke="#3379FF"
				stroke-width="2"
				stroke-linejoin="round"
			/>
		</svg>
	);
};

export default Filter;
