import { YMaps, Map, Placemark, ZoomControl } from "react-yandex-maps";
import * as React from "react";
import { useEffect, useState } from "react";
import s from "../complaintViews/views.module.css";
import { useTranslation } from "react-i18next";

export default function MapMainPageClaim({
	address,
	trigger,
	trigger2,
	setLonLat
}) {
	const { t, i18n } = useTranslation();
	const mapRef = React.useRef();
	const [center, setCenter] = useState([51.1453, 71.448024]);
	const [mapState, setMapState] = useState({
		center: center,
		zoom: 15
	});
	const [ymapsObject, setYmapsObject] = useState();
	const [placemark, setPlacemark] = useState();
	const renderPlacemarks = () => {
		<Placemark geometry={center} />;
	};
	useEffect(() => {
		if (ymapsObject) {
			ymapsObject
				.geocode(`Казахстан, ${address}`, { result: 1 })
				.then((res) => {
					var coords = res.geoObjects.get(0).geometry._coordinates;
					setLonLat(coords);
					placemark.geometry.setCoordinates(coords);
					mapRef.current.setCenter(coords);
					mapRef.current.setZoom(15);
					mapRef.current.geoObjects.add(placemark);
				})
				.catch((e) => console.log(e));
		}
	}, [address, trigger, trigger2, ymapsObject]);
	const handleMapClick = (e) => {
		const coordinates = e.get("coords");
		placemark.geometry.setCoordinates(coordinates);
		mapRef.current.geoObjects.add(placemark);
	};
	return (
		<>
			<YMaps query={{ apikey: "0ad49556-8ad4-46cc-bd44-5ac0803a9d4e" }}>
				<Map
					instanceRef={mapRef}
					defaultState={mapState}
					width="100%"
					onLoad={(ymaps) => {
						setYmapsObject(ymaps);
						setPlacemark(
							new ymaps.Placemark([51.1453, 71.448024], null, {
								preset: "islands#blueDotIcon"
							})
						);
					}}
					onClick={(e) => handleMapClick(e)}
					modules={["geocode", "Placemark", "geolocation"]}
				>
					<ZoomControl />
				</Map>
			</YMaps>
		</>
	);
}
