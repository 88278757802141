import React from "react";

const Location = () => {
	return (
		<svg
			width="21"
			height="21"
			viewBox="0 0 21 21"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<g clip-path="url(#clip0_1712_1169)">
				<path
					d="M18.375 8.75C18.375 14.875 10.5 20.125 10.5 20.125C10.5 20.125 2.625 14.875 2.625 8.75C2.625 6.66142 3.45469 4.65838 4.93153 3.18153C6.40838 1.70468 8.41142 0.875 10.5 0.875C12.5886 0.875 14.5916 1.70468 16.0685 3.18153C17.5453 4.65838 18.375 6.66142 18.375 8.75Z"
					stroke="black"
					stroke-width="2"
					stroke-linecap="round"
					stroke-linejoin="round"
				/>
				<path
					d="M10.5 11.375C11.9497 11.375 13.125 10.1997 13.125 8.75C13.125 7.30025 11.9497 6.125 10.5 6.125C9.05025 6.125 7.875 7.30025 7.875 8.75C7.875 10.1997 9.05025 11.375 10.5 11.375Z"
					stroke="black"
					stroke-width="2"
					stroke-linecap="round"
					stroke-linejoin="round"
				/>
			</g>
			<defs>
				<clipPath id="clip0_1712_1169">
					<rect width="21" height="21" fill="white" />
				</clipPath>
			</defs>
		</svg>
	);
};

export default Location;
