import React from "react";
import Select from "react-select";
import { Controller } from "react-hook-form";

const InputSelect = ({
	register,
	defaultValue,
	name,
	options,
	control,
	placeholder,
	setState,
	icon
}) => {
	const customStyles = {
		control: (provided, state) => ({
			...provided,
			outlineColor: "#A5A5A5",
			width: "100%",
			borderRadius: "5px",
			fontSize: 14,
			fontWeight: 400,
			fontFamily: "Montserrat",
			color: "#454545",
			border: "0.5px solid #A5A5A5",
			boxShadow: state.isFocused ? 0 : 0
		}),

		valueContainer: (provided) =>
			icon
				? {
						...provided,

						padding: "13px 30px 13px 49px",
						"&:focus": {
							border: "0.5px solid #A5A5A5",
							outline: "1px solid #A5A5A5"
						}
				  }
				: {
						...provided,

						padding: "13px 20px",
						color: "#454545",
						"&:focus": {
							border: "0.5px solid #A5A5A5",
							outline: "1px solid #A5A5A5"
						}
				  },
		placeholder: (provided) => ({
			...provided,
			color: "#454545"
		}),
		input: (provided) => ({
			...provided,
			color: "#454545",
			margin: 0,
			padding: 0
		}),
		indicatorSeparator: (provided) => ({
			...provided,
			backgroundColor: "none"
		}),

		dropdownIndicator: (provided) => ({
			...provided,
			color: "#26333D",
			paddingRight: "22px"
		})
	};

	return (
		<>
			<div style={{ position: "relative" }}>
				<Controller
					name={name || register?.name}
					control={control}
					render={({ field: { value, onChange, onBlur, ref } }) => {
						return (
							<Select
								styles={customStyles}
								ref={ref}
								theme={(theme) => ({
									...theme,
									borderRadius: 4,
									colors: {
										...theme.colors,
										primary: "#A5A5A5",
										color: "#454545"
									}
								})}
								closeMenuOnScroll={true}
								value={options?.filter((item) =>
									defaultValue
										? defaultValue === item.value
										: value === item.value
								)}
								isSearchable
								defaultValue={options?.filter(
									(item) => defaultValue === item.value
								)}
								onChange={(item) => {
									onChange(item?.value);
									setState ? setState(item.label) : null;
								}}
								getOptionValue={(option) => `${option["value"]}`}
								getOptionLabel={(label) => `${label["label"]}`}
								placeholder={placeholder}
								noOptionsMessage={() => "Совпадении не найдено"}
								options={options}
								components={{
									DropdownIndicator: () => (
										<div style={{ paddingRight: "20px" }}>
											<img src="/img/arrow.svg" alt="" />
										</div>
									)
								}}
							/>
						);
					}}
				/>
				{icon ? (
					<div style={{ position: "absolute", top: "11px", left: "15px" }}>
						{icon}
					</div>
				) : (
					<></>
				)}
			</div>
		</>
	);
};

export default InputSelect;
