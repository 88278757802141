import React from "react";

const StarGrey = () => {
	return (
		<svg
			width="21"
			height="21"
			viewBox="0 0 21 21"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				d="M10.5 1.75L13.2038 7.2275L19.25 8.11125L14.875 12.3725L15.9075 18.3925L10.5 15.5488L5.0925 18.3925L6.125 12.3725L1.75 8.11125L7.79625 7.2275L10.5 1.75Z"
				stroke="#555555"
				stroke-width="2"
				stroke-linecap="round"
				stroke-linejoin="round"
			/>
		</svg>
	);
};

export default StarGrey;
