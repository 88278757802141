import { YMaps, Map, Placemark } from "react-yandex-maps";
import * as React from "react";
import { useEffect, useState } from "react";
import s from "../complaintViews/views.module.css";
import { useTranslation } from "react-i18next";

export default function MapComponent({ latitude, longitude }) {
	const { t, i18n } = useTranslation();
	const [isChange, setIsChange] = useState(false);

	useEffect(() => {
		if (!isChange) {
			setIsChange(true);
		}
	}, [latitude]);

	const mapData = {
		center: [latitude, longitude],
		zoom: 15
	};

	const coordinates = [latitude, longitude];

	const lat = document.getElementById("lat");
	const lng = document.getElementById("lng");

	const getCoordinate = (e) => {
		const coor = e.get("originalEvent")?.originalEvent;
		if (coor !== undefined) {
			if (
				coordinates[0] !== coor?.newCoordinates[0] ||
				coordinates[1] !== coor?.newCoordinates[1]
			) {
				lat.innerHTML = coor?.newCoordinates[0];
				lng.innerHTML = coor?.newCoordinates[1];
			}
		}
	};

	return (
		<>
			{isChange && (
				<YMaps>
					<Map defaultState={mapData} width="100%" id={"myMap"}>
						<Placemark
							geometry={coordinates}
							options={{ draggable: true }}
							instanceRef={(ref) => {
								ref?.events.add(["geometrychange", "dragend"], function (e) {
									getCoordinate(e);
								});
							}}
						/>
					</Map>
				</YMaps>
			)}
			<div className={s.form}>
				<p
					className={s.p}
					style={{
						marginBottom: `10px`,
						marginTop: `20px`
					}}
				>
					{t("labels.lange")}: <span id={"lat"}></span>
				</p>
				<p
					className={s.p}
					style={{
						marginBottom: `10px`,
						marginTop: `20px`
					}}
				>
					{t("labels.long")}: <span id={"lng"}></span>
				</p>
			</div>
		</>
	);
}
