import React from "react";

const Smile = () => {
	return (
		<svg
			width="21"
			height="21"
			viewBox="0 0 21 21"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<g clip-path="url(#clip0_1712_1175)">
				<path
					d="M10.5 19.25C15.3325 19.25 19.25 15.3325 19.25 10.5C19.25 5.66751 15.3325 1.75 10.5 1.75C5.66751 1.75 1.75 5.66751 1.75 10.5C1.75 15.3325 5.66751 19.25 10.5 19.25Z"
					stroke="#CE4B4B"
					stroke-width="2"
					stroke-linecap="round"
					stroke-linejoin="round"
				/>
				<path
					d="M14 14C14 14 12.6875 12.25 10.5 12.25C8.3125 12.25 7 14 7 14"
					stroke="#CE4B4B"
					stroke-width="2"
					stroke-linecap="round"
					stroke-linejoin="round"
				/>
				<path
					d="M7.875 7.875H7.885"
					stroke="#CE4B4B"
					stroke-width="2"
					stroke-linecap="round"
					stroke-linejoin="round"
				/>
				<path
					d="M13.125 7.875H13.135"
					stroke="#CE4B4B"
					stroke-width="2"
					stroke-linecap="round"
					stroke-linejoin="round"
				/>
			</g>
			<defs>
				<clipPath id="clip0_1712_1175">
					<rect width="21" height="21" fill="white" />
				</clipPath>
			</defs>
		</svg>
	);
};

export default Smile;
