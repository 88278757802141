import React, { useState } from "react";
import s from "./feedback.module.css";
import { useTranslation } from "react-i18next";
import axios from "axios";

const FeedbackModal = ({ setIsModal }) => {
	const { t, i18n } = useTranslation();
	const [isSend, setIsSend] = useState(false);
	const BaseURL = process.env.REACT_APP_URL_API;

	const [message, setMessage] = useState("");

	const onChange = (e) => {
		setMessage(e.target.value);
	};
	const onSubmit = () => {
		const data = { message: message };
		if (message !== "") {
			axios.post(`${BaseURL}/v1/feedback`, data).then((res) => {
				res.data.message === "Сообщение отправленно" && setIsSend(true);
				setTimeout(() => {
					setIsModal(false);
					setIsSend(false);
				}, 2000);
			});
		}
	};

	return (
		<div className={s.modal}>
			<div className={s.modalWrapper}>
				<div
					className={s.modalControls}
					onClick={() => {
						setIsModal(false);
					}}
				>
					<svg
						width="25"
						height="25"
						viewBox="0 0 25 25"
						fill="none"
						xmlns="http://www.w3.org/2000/svg"
					>
						<path
							d="M9.552 15.4478L15.4478 9.552M15.4478 15.4478L9.552 9.552M12.4999 22.9166C18.2291 22.9166 22.9166 18.2291 22.9166 12.4999C22.9166 6.77075 18.2291 2.08325 12.4999 2.08325C6.77075 2.08325 2.08325 6.77075 2.08325 12.4999C2.08325 18.2291 6.77075 22.9166 12.4999 22.9166Z"
							stroke="#0E1839"
							stroke-width="1.5"
							stroke-linecap="round"
							stroke-linejoin="round"
						/>
					</svg>
				</div>
				<div className={s.modalContent}>
					{isSend ? (
						<div className={s.feedbackThanx}>
							<h6 className={s.feedbackThanxTitle}>{t("feedbackThanx")}</h6>
							<p>{t("feedbackSuccess")}</p>
						</div>
					) : (
						<div className={s.feedbackModal}>
							<div className={s.feedbackModalTitle}>
								<h5>{t("feedbackTitle")}</h5>
								<p>{t("feedbackSubTitle")}</p>
							</div>
							<textarea
								rows={8}
								placeholder={`${t("feedbackYourText")}`}
								className={s.feedbackModalTextarea}
								onChange={(e) => onChange(e)}
							/>
							<button className={s.feedbackModalButton} onClick={onSubmit}>
								{t("send")}
							</button>
						</div>
					)}
				</div>
			</div>
		</div>
	);
};

export default FeedbackModal;
