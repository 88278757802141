import { Link } from "react-router-dom";
import s from "./modal.module.css";
import { useTranslation } from "react-i18next";

export default function Modal({ setIsModal }) {
	const { t, i18n } = useTranslation();

	return (
		<div className={s.modalWrapper}>
			<button
				className={s.btn}
				onClick={() => {
					setIsModal(false);
				}}
			>
				<img src="/img/cancel.svg" alt="cancel" />
			</button>
			<div className={s.modalContent}>
				<img src="/img/success.svg" alt="success" />
				<h5 className={s.h5}>{t("modal.para_1")}</h5>
				<h4 className={s.h4}>{t("modal.para_2")}</h4>
				<p className={s.p}>{t("modal.para_3")}</p>
				<Link to={"/"} className={s.a} onClick={() => setIsModal(false)}>
					{t("modal.btn")}
				</Link>
			</div>
		</div>
	);
}
