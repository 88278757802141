import React, { useState, useEffect } from "react";
import axios from "axios";
import { useTranslation } from "react-i18next";
import { useForm } from "react-hook-form";
import InputSelect from "../Select";
import { v4 as uuidv4 } from "uuid";
import MapMainPageClaim from "../Map/MapMainPageClaim";

const ComplaintsMainForm = ({ isModal, setIsModal }) => {
	const { t, i18n } = useTranslation();
	const BaseURL = process.env.REACT_APP_URL_API;
	const {
		register,
		control,
		handleSubmit,
		watch,
		reset,
		formState: { errors }
	} = useForm();

	const [files, setFiles] = useState([]);
	const [images, setImages] = useState([]);
	const [region, setRegion] = useState();
	const gender = [
		{ value: "man", label: `${t("main.male")}` },
		{ value: "woman", label: `${t("main.female")}` }
	];
	let regions = [];
	const [cities, setCities] = useState([]);
	useEffect(() => {
		axios.get(`${BaseURL}/regions`).then((res) => setRegion(res.data));
	}, []);
	region?.map((item) => {
		if (i18n.language == "en") {
			regions.push({
				value: item.id,
				label: item.translations[0].name
			});
		} else if (i18n.language == "kz") {
			regions.push({
				value: item.id,
				label: item.translations[1].name
			});
		} else {
			regions.push({
				value: item.id,
				label: item.translations[2].name
			});
		}
	});
	const citiesOption = cities?.map((item) => ({
		value: item?.id,
		label: item?.translation?.name
	}));
	useEffect(() => {
		const extractedCities = region
			?.filter((item) => item?.id == watch("region_id"))
			?.flatMap((item) => item.settlment);

		setCities(extractedCities);
	}, [watch("region_id"), region]);

	const [address, setAddress] = useState("");
	const [regionValue, setRegionValue] = useState("");
	const [cityValue, setCityValue] = useState("");
	const [lonLat, setLonLat] = useState([]);
	useEffect(() => {
		setAddress(
			`${watch("region_id") === undefined ? "" : regionValue}, ${
				cityValue == "Другое" ? "" : cityValue
			}`
		);
	}, [watch("city_id"), watch("region_id")]);
	const handleFiles = (e) => {
		const selectedFiles = Array.from(e.target.files);
		setFiles(selectedFiles);
	};

	useEffect(() => {
		if (files.length > 0) {
			setImages((prevItems) =>
				prevItems.concat(files.map((file) => ({ id: uuidv4(), image: file })))
			);
		}
	}, [files]);

	const handleImageRemove = (idToRemove) => {
		setImages((prevImages) =>
			prevImages.filter((item) => item.id !== idToRemove)
		);
	};
	const onSubmit = async (data) => {
		try {
			const formData = new FormData();
			formData.append("complaint_id", 7);
			formData.append("region_id", data.region_id);
			formData.append("negatives[]", JSON.stringify(7));
			formData.append("comment_text", data.comment);
			formData.append("comment", data.comment);
			formData.append("client_email", data.email);
			formData.append("client_phone", data.client_phone);
			formData.append("address", data.address);
			formData.append("client_name", "другое");
			formData.append("longitude", lonLat[0]);
			formData.append("lat", lonLat[1]);
			formData.append("name", "другое");
			const binaryDataArray = [];
			images.forEach((item, index) => {
				formData.append(
					"photos[]",
					item.image,
					`photo${index}.${item.image.type.split("/")[1]}`
				);
			});

			await axios.post(`${BaseURL}/claim`, formData, {
				headers: {
					"Content-Type": "multipart/form-data"
				}
			});

			reset();
			setIsModal(true);
		} catch (error) {
			console.error("Error:", error);
		}

		// setTimeout(() => {}, 3000);
	};

	return (
		<>
			<div className="send_form">
				<div className="send_form-title">
					<img
						src="/img/message.png"
						alt="icon"
						className="send_form-title-img"
					/>
					{t("main.takePhoto")}
				</div>
				<div className="complaints_from">
					<div className="complaints_from-images-wrapper">
						{images &&
							images
								.slice()
								.reverse()
								?.map((item, index) => (
									<div key={index} className="complaints_from-images">
										<img
											className="complaints_from-image"
											src={URL.createObjectURL(item.image)}
											alt={`Image ${index}`}
										/>
										<img
											className="complaints_from-icon"
											src="/img/delete.svg"
											alt=""
											onClick={() => handleImageRemove(item.id)}
										/>
									</div>
								))}
					</div>
					<form encType="multipart/form-data" onSubmit={handleSubmit(onSubmit)}>
						<>
							<div className="complaints_top">
								<div>
									<input
										type="file"
										name={"picture"}
										id={"cameraFile"}
										onChange={(e) => handleFiles(e)}
										accept="image/png, image/gif, image/jpeg, image/jpg"
										capture="camera"
										multiple
									/>
									<label
										htmlFor="cameraFile"
										className="complaints_photo complaints_photo-blue"
									>
										<img src="/img/camera.svg" alt="file" />
										<p>{t("main.takePhoto2")}</p>
									</label>
								</div>
								<div>
									<input
										type="file"
										name={"chooseFile"}
										id={"chooseFile"}
										onChange={(e) => handleFiles(e)}
										accept="image/png, image/gif, image/jpeg, image/jpg"
										multiple
									/>
									<label
										htmlFor="chooseFile"
										className="complaints_photo complaints_photo-grey"
									>
										<img src="/img/filegrey.svg" alt="file" />
										<p>{t("labels.pastePic")}</p>
									</label>
								</div>
							</div>
							<hr className="complaints_from-hr" />
							<p className="complaints_from-fill">{t("main.fill")}</p>
							<div className="complaints_from-inputs">
								<div className="relative">
									<InputSelect
										options={regions}
										control={control}
										setState={setRegionValue}
										placeholder={`${t("main.region")}`}
										register={{ ...register(`region_id`, { required: true }) }}
									/>
								</div>
								{errors.region_id && (
									<p
										style={{
											color: `red`
										}}
									>
										{t("labels.err")}
									</p>
								)}
								<div className="relative">
									<InputSelect
										options={citiesOption}
										setState={setCityValue}
										control={control}
										placeholder={`Город`}
										register={{ ...register(`city_id`) }}
									/>
								</div>
								<input
									className="complaints_from-input"
									type="text"
									placeholder={`${t("main.address")}`}
									{...register("address", { required: true })}
								/>
								{errors.address && (
									<p
										style={{
											color: `red`
										}}
									>
										{t("labels.err")}
									</p>
								)}
								<MapMainPageClaim
									address={address}
									trigger={watch(`region_id`)}
									trigger2={watch(`city_id`)}
									setLonLat={setLonLat}
								/>
								{/* тут будет карта */}
								<input
									className="complaints_from-input"
									type="text"
									placeholder="Email"
									{...register("email", { required: true })}
								/>
								{errors.email && (
									<p
										style={{
											color: `red`
										}}
									>
										{t("labels.err")}
									</p>
								)}
								<input
									className="complaints_from-input"
									type="tel"
									placeholder={`${t("main.phone")}`}
									{...register("client_phone", { required: true })}
								/>
								{errors.client_phone && (
									<p
										style={{
											color: `red`
										}}
									>
										{t("labels.err")}
									</p>
								)}
								{/* <div className="relative">
									<InputSelect
										options={gender}
										control={control}
										placeholder={`${t("main.gender")}`}
										register={{ ...register(`gender`) }}
									/>
								</div> */}
								<textarea
									placeholder={`${t("main.describe")}`}
									type="text"
									className="complaints_from-textarea"
									{...register("comment", { required: true })}
								/>
								{errors.comment && (
									<p
										style={{
											color: `red`
										}}
									>
										{t("labels.err")}
									</p>
								)}
							</div>
							<button type="submit" className="complaints_from-send">
								{t("main.sendClaim")}
							</button>
						</>
					</form>
				</div>
			</div>
		</>
	);
};

export default ComplaintsMainForm;
