import React, { useState } from "react";
import s from "./feedback.module.css";
import FeedbackModal from "./FeedbackModal";

const Feedback = () => {
	const [isModal, setIsModal] = useState(false);

	return (
		<>
			<div className={s.feedback} onClick={() => setIsModal(true)}>
				<svg
					width="174"
					height="174"
					viewBox="0 0 174 174"
					fill="none"
					xmlns="http://www.w3.org/2000/svg"
				>
					<g filter="url(#filter0_d_1_3887)">
						<circle cx="87" cy="71" r="37" fill="#489ED4" />
					</g>
					<path
						d="M85.5 73C86.0523 73 86.5 72.5523 86.5 72C86.5 71.4477 86.0523 71 85.5 71C84.9477 71 84.5 71.4477 84.5 72C84.5 72.5523 84.9477 73 85.5 73Z"
						fill="white"
					/>
					<path
						d="M89.5 73C90.0523 73 90.5 72.5523 90.5 72C90.5 71.4477 90.0523 71 89.5 71C88.9477 71 88.5 71.4477 88.5 72C88.5 72.5523 88.9477 73 89.5 73Z"
						fill="white"
					/>
					<path
						d="M93.5 73C94.0523 73 94.5 72.5523 94.5 72C94.5 71.4477 94.0523 71 93.5 71C92.9477 71 92.5 71.4477 92.5 72C92.5 72.5523 92.9477 73 93.5 73Z"
						fill="white"
					/>
					<path
						d="M96.2 65H94V61.86C94 61.3667 93.804 60.8936 93.4552 60.5448C93.1064 60.196 92.6333 60 92.14 60H77.86C77.3667 60 76.8936 60.196 76.5448 60.5448C76.196 60.8936 76 61.3667 76 61.86V73.14C76 73.6333 76.196 74.1064 76.5448 74.4552C76.8936 74.804 77.3667 75 77.86 75H81V77.2C81.0026 77.6766 81.1931 78.1329 81.5301 78.4699C81.8671 78.8069 82.3234 78.9974 82.8 79H90.28L91 81.27C91.0735 81.5253 91.2177 81.7546 91.4158 81.9315C91.614 82.1084 91.8581 82.2257 92.12 82.27H92.37C92.6119 82.2709 92.8498 82.2086 93.0603 82.0893C93.2708 81.9701 93.4464 81.798 93.57 81.59L94.52 79H96.2C96.6766 78.9974 97.1329 78.8069 97.4699 78.4699C97.8069 78.1329 97.9974 77.6766 98 77.2V66.8C97.9974 66.3234 97.8069 65.8671 97.4699 65.5301C97.1329 65.1931 96.6766 65.0026 96.2 65ZM78 73V62H92V65H82.8C82.3234 65.0026 81.8671 65.1931 81.5301 65.5301C81.1931 65.8671 81.0026 66.3234 81 66.8V73H78ZM96 77H93.08L92.4 79L91.72 77H83V67H96V77Z"
						fill="white"
					/>
					<defs>
						<filter
							id="filter0_d_1_3887"
							x="0"
							y="0"
							width="174"
							height="174"
							filterUnits="userSpaceOnUse"
							colorInterpolationFilters="sRGB"
						>
							<feFlood floodOpacity="0" result="BackgroundImageFix" />
							<feColorMatrix
								in="SourceAlpha"
								type="matrix"
								values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
								result="hardAlpha"
							/>
							<feOffset dy="16" />
							<feGaussianBlur stdDeviation="25" />
							<feComposite in2="hardAlpha" operator="out" />
							<feColorMatrix
								type="matrix"
								values="0 0 0 0 0.282353 0 0 0 0 0.619608 0 0 0 0 0.831373 0 0 0 0.3 0"
							/>
							<feBlend
								mode="normal"
								in2="BackgroundImageFix"
								result="effect1_dropShadow_1_3887"
							/>
							<feBlend
								mode="normal"
								in="SourceGraphic"
								in2="effect1_dropShadow_1_3887"
								result="shape"
							/>
						</filter>
					</defs>
				</svg>
			</div>
			{isModal && <FeedbackModal setIsModal={setIsModal} />}
		</>
	);
};

export default Feedback;
