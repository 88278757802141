import axios from "axios";
import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { useForm } from "react-hook-form";
import s from "./views.module.css";
import { useTranslation } from "react-i18next";
import InputSelect from "../Select";
import Filter from "../icons/Filter";
import Search from "../icons/Search";
import RaitingSelect from "../Select/raitingSelect";
import Location from "../icons/Location";
import { Rating } from "@mui/material";
import Star from "../icons/Star";
import StarGrey from "../icons/StarGrey";
import Smile from "../icons/Smile";
import Bed from "../icons/Bed";
import Person from "../icons/Person";
import Trash from "../icons/Trash";
import CloseFilter from "../icons/CloseFilter";
import Alarm from "../icons/Alarm";
import Tube from "../icons/Tube";
import Pagination from "@mui/material/Pagination";
import Icon1 from "../icons/Icon1";
import Icon2 from "../icons/Icon2";

function Raiting({ langCH }) {
	const { t, i18n } = useTranslation();
	const [region, setRegion] = useState();
	const BaseURL = process.env.REACT_APP_URL_API;
	const [isModal, setIsModal] = useState(false);
	const [activeRatingId, setActiveRatingId] = useState();
	const [negativeId, setNegativeId] = useState();
	const [openFilter, setOpenFilter] = useState(false);
	const {
		register,
		handleSubmit,
		reset,
		control,
		watch,
		formState: { errors }
	} = useForm();
	let regions = [];
	useEffect(() => {
		axios.get(`${BaseURL}/regions`).then((res) => setRegion(res.data));
	}, []);
	region?.map((item) => {
		if (i18n.language == "en") {
			regions.push({
				value: item.id,
				label: item.translations[0].name
			});
		} else if (i18n.language == "kz") {
			regions.push({
				value: item.id,
				label: item.translations[1].name
			});
		} else {
			regions.push({
				value: item.id,
				label: item.translations[2].name
			});
		}
	});
	const [negatives, setNegatives] = useState();
	const icons = [
		{ id: 1, icon: <Bed />, title: "a" },
		{ id: 2, icon: <Person />, title: "б" },
		{ id: 3, icon: <Trash />, title: "в" },
		{ id: 4, icon: <Bed />, title: "г" },
		{ id: 5, icon: <Alarm />, title: "a" },
		{ id: 6, icon: <Tube />, title: "a" }
	];
	useEffect(() => {
		axios.get(`${BaseURL}/negatives`).then((res) => {
			let negative = [];
			res?.data.slice(0, 6).map((translations) =>
				translations.translations.map((item) => {
					if (item.lang === langCH) {
						negative.push({
							id: item.id,
							name: item.name
						});
					}
				})
			);
			setNegatives(negative);
		});
	}, [langCH]);
	const [sideFilter, setSideFilter] = useState();
	useEffect(() => {
		const mergedArray = icons.map((item1) => {
			const correspondingItem = negatives?.find(
				(item2) => item2.id === item1.id
			);
			return { ...item1, ...correspondingItem };
		});
		setSideFilter(mergedArray);
	}, [negatives]);

	const [data, setData] = useState();
	const [meta, setMeta] = useState();
	const [page, setPage] = useState(1);
	const regionId = watch("region_id");
	const [search, setSearch] = useState("");
	// get name of the complaints
	useEffect(() => {
		let filterData = "";
		if (regionId) {
			filterData = filterData.concat(`&region_id=${regionId}`);
		}
		if (negativeId) {
			filterData = filterData.concat(`&negative_id=${negativeId}`);
		}
		if (search) {
			filterData = filterData.concat(`&query=${search}`);
		}
		if (activeRatingId) {
			filterData = filterData.concat(`&orderBy=${activeRatingId}`);
		}
		axios
			.get(`${BaseURL}/tour-rating?per_page=15&page=${page}${filterData}`)
			.then((res) => {
				setData(res.data.hotels.data);
				setMeta(res.data.hotels);
			});
	}, [regionId, negativeId, search, activeRatingId, page]);
	const handlePageChange = (event, page) => {
		setPage(page);
	};
	return (
		<>
			<div className={"complaint_views"}>
				<div className="background_cover" />
				<div className="views_content">
					<div className="wrapper raiting__wrapper">
						<div className="header raiting__header">
							<div className="back">
								<Link to={"/"}>
									<img src="/img/back.svg" alt="back" />
									<span>{t("back")}</span>
								</Link>
							</div>
							<div className={s.title}>
								<div className={s.content}>
									<div className={s.line} />
									<h5
										className={s.h5}
										dangerouslySetInnerHTML={{
											__html: `${t("raiting.tourobjectsRaiting")}`
										}}
									></h5>
								</div>{" "}
								<div onClick={() => setOpenFilter(!openFilter)}>
									{openFilter ? <CloseFilter /> : <Filter />}
								</div>
								{openFilter && (
									<div className={s.filterNegative}>
										{negatives?.map((item, index) => (
											<div
												className={`${s.filterNegativeItem} ${
													negativeId === item.id
														? s.filterNegativeItemActive
														: ""
												}`}
												key={item.id}
												onClick={() => {
													if (negativeId === item.id) {
														setNegativeId("");
													} else {
														setNegativeId(item.id);
														setOpenFilter(false);
													}
												}}
											>
												<img
													src={
														negativeId === item.id
															? `/img/iconActive${item.id}.svg`
															: `/img/icon${item.id}.svg`
													}
												/>
												<span>{item.name}</span>
											</div>
										))}
									</div>
								)}
							</div>
							<div className={s.filter}>
								<div className={s.inputWrapper}>
									<input
										type="text"
										className={s.input}
										placeholder={`${t("raiting.search")}`}
										onChange={(e) => {
											setSearch(e.target.value);
										}}
									/>
									<Search />
								</div>
								<RaitingSelect
									options={regions}
									control={control}
									placeholder={`${t("main.region")}`}
									register={{ ...register(`region_id`) }}
								/>
								<div className={s.filterRaiting}>
									<strong> {t("raiting.title")}</strong>
									<div className={s.filterRaitingItems}>
										<p
											onClick={() => {
												if (activeRatingId === "asc") {
													setActiveRatingId("");
												} else setActiveRatingId("asc");
											}}
											className={
												activeRatingId === "asc" && s.filterRaitingActive
											}
										>
											{t("raiting.low")}
										</p>
										<p
											onClick={() => {
												if (activeRatingId === "desc") {
													setActiveRatingId("");
												} else setActiveRatingId("desc");
											}}
											className={
												activeRatingId === "desc" && s.filterRaitingActive
											}
										>
											{t("raiting.high")}
										</p>
									</div>
								</div>
							</div>{" "}
							<div className={s.hotelsWrapper}>
								{data?.length > 0 ? (
									<>
										{data?.map((item) => (
											<div className={s.hotel}>
												<h3>{item.name_ru}</h3>
												<div className={s.hotelLocation}>
													<Location /> {item.address_ru}
												</div>
												<Rating
													name="text-feedback"
													value={Number(item.fields.star)}
													readOnly
													emptyIcon={<StarGrey />}
													icon={<Star />}
												/>
												<div className={s.hotelCount}>
													<Smile />{" "}
													<p>
														{t("raiting.complainAmount")}
														<strong> {item.complaints_count}</strong>
													</p>
												</div>
											</div>
										))}
										{meta.last_page > 1 && (
											<div className="pagination__wrapper">
												<Pagination
													onChange={handlePageChange}
													page={page}
													count={meta.last_page}
													color="primary"
												/>
											</div>
										)}
									</>
								) : (
									<p>{t("raiting.notFound")}</p>
								)}
							</div>
						</div>
					</div>
				</div>
			</div>
		</>
	);
}

export default Raiting;
