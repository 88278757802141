import React from "react";

const Search = () => {
	return (
		<svg
			width="17"
			height="17"
			viewBox="0 0 17 17"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<circle
				cx="7.79171"
				cy="7.79171"
				r="4.95833"
				stroke="black"
				stroke-width="2"
			/>
			<path
				d="M14.1666 14.1666L12.0416 12.0416"
				stroke="black"
				stroke-width="2"
				stroke-linecap="round"
			/>
		</svg>
	);
};

export default Search;
