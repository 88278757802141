import Navbar from "./components/Navbar";
import Complaints from "./components/complaints/Сomplaints";
import ComplaintViews from "./components/complaintViews/complaintViews";
import { Routes, Route } from "react-router-dom";
import "./App.css";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { isMobile } from "react-device-detect";
import Feedback from "./components/Feedback/Feedback";
import Raiting from "./components/raiting/Raiting";

function App() {
	const { t, i18n } = useTranslation();
	const [langCH, setLangCH] = useState(i18n.language);

	useEffect(() => {
		!isMobile && (window.location = "https://shagym.kz/");
	});

	return (
		<div>
			<Navbar setLangCH={setLangCH} />
			<Routes>
				<Route path="/" element={<Complaints langCH={langCH} />} />
				<Route
					path="/complaint-views/*"
					element={<ComplaintViews langCH={langCH} />}
				/>
				<Route path="/raiting" element={<Raiting langCH={langCH} />} />
			</Routes>
			<Feedback />
		</div>
	);
}

export default App;
